import React, { Component } from "react";
import NavDE from "../Symbols/NavDE";
import { Link } from "react-router-dom";
import FooterDE from "../Symbols/FooterDE";
import ASN1 from "../../assets/AS_N_1.jpg";
import ASN6 from "../../assets/AS_N_6.jpg";
import MetaTags from "react-meta-tags";
import "../../stylesheets/AboutUs.css";

class AutomotiveSolutions_New extends Component {
  constructor(props) {
    super(props);
    this.intro = React.createRef();
  }

  introScroll = () => {
    let element = this.intro.current;
    let headerOffset = 45;
    let elementPosition = element.getBoundingClientRect().top;
    let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  render() {
    return (
      <div className="content">
        <MetaTags>
          <title>Pancon - Automotive Solutions</title>
          <meta
            name="description"
            content="Entdecken Sie hochwertige Steckverbinder und innovative
Verbindungslösungen von Pancon – präzise, zuverlässig und ideal für Automotive-
und Industrieanwendungen. Individuelle Steckverbinder für Ihre Anforderungen."
          />
          <meta property="og:title" content="Pancon - Automotive Solutions" />
          <meta
            property="og:description"
            content="Entdecken Sie hochwertige Steckverbinder und innovative
Verbindungslösungen von Pancon – präzise, zuverlässig und ideal für Automotive-
und Industrieanwendungen. Individuelle Steckverbinder für Ihre Anforderungen."
          />
          <link
            rel="alternate"
            href="https://www.pancon.de/de/automotive-solutions"
            hrefLang="de"
          ></link>
          <link
            rel="alternate"
            href="https://www.pancon.de/en/automotive-solutions"
            hrefLang="en"
          ></link>
        </MetaTags>
        <NavDE />
        <div className="hero auto-sol">
          <div className="red-bg-hero"></div>
          <div className="hero-content new">
            <h1 className="hero-heading">Pancon Automotive und Industrial Solutions</h1>
            <h2 className="hero-heading-2">Präzision und Zuverlässigkeit
              für Ihre Branche</h2>
            <p className="hero-desc new">
              Willkommen bei Pancon, Ihrem Partner für innovative Verbindungslösungen in den
              Bereichen <strong>Automotive</strong> und <strong>Industrial Solutions</strong>. Mit jahrzehntelanger Erfahrung
              entwickeln wir maßgeschneiderte Steckverbinder und Komponenten, die höchsten
              Qualitätsstandards entsprechen und selbst in anspruchsvollsten Anwendungen
              überzeugen.
            </p>
            <button onClick={this.introScroll} className="default inverted">
              Mehr erfahren
            </button>
          </div>
        </div>
        <div ref={this.intro} id="intro" className="section first flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <h2 className="alternate-heading">Automotive Solutions – Innovation für die Mobilität der Zukunft</h2>
            </div>
            <p className="body padding-default">
              Die Automobilindustrie steht vor einem Paradigmenwechsel: Elektromobilität, autonomes Fahren und vernetzte Fahrzeuge erfordern fortschrittliche Verbindungstechnologien. Pancon liefert Lösungen, die perfekt auf diese Herausforderungen zugeschnitten sind:
            </p>
            <div className="all-thirds-container flex">
              <div className="third-container third">
                <img className="img-full" src={ASN1}></img>
                <h4>Hochleistungskomponenten</h4>
                <p className="body">
                  Unsere Steckverbinder gewährleisten eine zuverlässige Daten- und Stromübertragung – auch unter extremen Bedingungen.
                </p>
              </div>
              <div className="third-container third">
                <img className="img-full" src={ASN1}></img>
                <h4>Anpassungsfähigkeit</h4>
                <p className="body">
                  Dank modularem Design passen sich unsere Produkte den Anforderungen von Elektrofahrzeugen (EV), Hybridantrieben und klassischen Verbrennern an.
                </p>
              </div>
              <div className="third-container third">
                <img className="img-full" src={ASN1}></img>
                <h4>Robustheit und Sicherheit</h4>
                <p className="body">
                  Vibrations- und hitzebeständige Materialien sorgen für Langlebigkeit und eine fehlerfreie Funktion.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section full about-1 new-margin flex center-h center-v">
          <div className="half about-img-2"></div>
          <div className="half red-bg">
            <h4>Industrial Solutions</h4>
            <h2>Effizienz und Zuverlässigkeit für Industrieanwendungen</h2>
            <p className="body inverted">
              Industrie 4.0, Automatisierung und IoT stellen hohe Anforderungen an Verbindungssysteme. Unsere Lösungen bieten Unternehmen in verschiedensten Branchen die notwendige Performance, um diese Anforderungen zu erfüllen:
            </p>
            <ul className="body">
              <li><strong>Vielfältige Anwendungen: </strong>Unsere Steckverbinder finden Einsatz in industriellen Steuerungssystemen, Maschinenbau, erneuerbaren Energien und mehr.</li>
              <li><strong>Hochwertige Materialien: </strong>Chemikalienresistente und langlebige Komponenten garantieren den Betrieb auch in aggressiven Umgebungen.</li>
              <li><strong>Kundenspezifische Lösungen: </strong>Wir entwickeln gemeinsam mit Ihnen maßgeschneiderte Produkte, die perfekt zu Ihrer Anwendung passen.</li>
            </ul>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="heading1-cont flex center-v">
              <h2 className="alternate-heading">Warum Pancon?</h2>
            </div>
            <div className="half">
              <ul className="body">
                <li><strong>Erfahrung und Know-how: </strong>Über Jahrzehnte gewonnene Branchenkenntnisse machen uns zu einem vertrauenswürdigen Partner.</li>
                <li><strong>Technologische Exzellenz: </strong>Kontinuierliche Forschung und Entwicklung gewährleisten zukunftssichere Produkte.</li>
                <li><strong>Nachhaltigkeit: </strong>Umweltfreundliche Materialien und Prozesse sind fester Bestandteil unserer Philosophie.</li>
                <li><strong>Globale Präsenz: </strong>Mit einem internationalen Netzwerk begleiten wir Ihre Projekte weltweit.</li>
              </ul>
            </div>
            <div className="half w-image">
              <img src={ASN6}></img>
            </div>
          </div>
        </div>
        <div className="section flex center-h">
          <div className="medium flex">
            <div className="half flex center-v center-h">
              <p className="eye-catcher accent">Pancon - Verbindungstechnik für eine vernetzte Welt</p>
              <p className="body s-18">
              Industrie 4.0, Automatisierung und IoT stellen hohe Anforderungen an Verbindungssysteme. Unsere Lösungen bieten Unternehmen in verschiedensten Branchen die notwendige Performance, um diese Anforderungen zu erfüllen:
            </p>
            </div>
            <div className="half flex center-v center-h col">
              <Link className="default diff-1" to="/de/kontakt">
                <span>Kontaktieren Sie uns jetzt</span>
              </Link>
              <p className="body s-18">Wir freuen uns auf Ihre Anfrage!</p>
            </div>
          </div>
        </div>
        <FooterDE />
      </div>
    );
  }
}

export default AutomotiveSolutions_New;
